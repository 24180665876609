var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"the-novel-join px",class:{ mobile: _vm.isMobile, cn: _vm.isCN }},[_c('v-header',{attrs:{"title":_vm.$t('TheNovelJoin.name')}}),_c('img',{staticClass:"image",attrs:{"src":_vm.isMobile
        ? _vm.isCN
          ? _vm.oss.the_novel_project_kv
          : _vm.oss.the_novel_project_kv
        : _vm.isCN
        ? _vm.oss.the_novel_project_kv
        : _vm.oss.the_novel_project_kv,"alt":"AM AFA BLADE"}}),_c('img',{staticClass:"image image01",attrs:{"src":_vm.oss.the_novel_join_01,"alt":"AM AFA BLADE"}}),_c('div',{staticClass:"title title01",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.title01'))}}),_c('div',{staticClass:"text text01",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.text01'))}}),_c('div',{staticClass:"title title02",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.title02'))}}),_c('div',{staticClass:"text text02",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.text02'))}}),_c('div',{staticClass:"title title03",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.title03'))}}),_c('div',{staticClass:"text text03",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.text03'))}}),_c('div',{staticClass:"tip tip01",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.tip01'))}}),_c('img',{staticClass:"image image02",attrs:{"src":_vm.oss.the_novel_join_02,"alt":"AM AFA BLADE"}}),_c('v-swiper',{attrs:{"images":[
      _vm.oss.the_novel_project_template_01,
      _vm.oss.the_novel_project_template_02,
      _vm.oss.the_novel_project_template_03,
      _vm.oss.the_novel_project_template_04,
      _vm.oss.the_novel_project_template_05
    ],"names":[]}}),_c('a',{staticClass:"image-text image-text01",attrs:{"href":_vm.oss.the_novel_project_template_psd},domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.imageText01'))}}),_c('div',{staticClass:"text text04",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.text04'))}}),_c('img',{staticClass:"image image03",attrs:{"src":_vm.oss.the_novel_join_03,"alt":"AM AFA BLADE"}}),_c('div',{staticClass:"text text05",domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.text05'))}}),_c('img',{staticClass:"image image04",attrs:{"src":_vm.oss.the_novel_join_04,"alt":"AM AFA BLADE"}}),_c('v-swiper',{attrs:{"images":[
      _vm.oss.the_novel_project_cyberboard_01,
      _vm.oss.the_novel_project_cyberboard_02,
      _vm.oss.the_novel_project_cyberboard_03
    ],"names":[]}}),_c('a',{staticClass:"image-text image-text02",attrs:{"href":_vm.oss.the_novel_project_cyberboard_psd},domProps:{"innerHTML":_vm._s(_vm.$t('TheNovelJoin.imageText02'))}}),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }